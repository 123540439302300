import React from 'react';
import {Router} from '@reach/router';
import Login from './Login';
import loadable from '@loadable/component';
// import {BulkUpload} from './BulkUpload';

const EmailSearch = loadable(() => import('./EmailSearch'));

/**
 *
 *
 * @return {*}
 */
function ReactivationAgent() {
  return (
    <>
      <Router basepath='/reactivation-agent'>
        <Login path="/login" />
        {/* <BulkUpload path="/reactivation-upload" /> */}
        <EmailSearch path="/email-search" />
      </Router>
    </>
  );
}

export default ReactivationAgent;
